<template>
  <div>
    <div class="breadcrumbs">
      <div class="breadcrumb">
        <router-link class="breadcrumb-link" :to="{ name: 'admin.trainings' }">Academy</router-link>
      </div>
      <div class="breadcrumb">
        <router-link class="breadcrumb-link" v-if="event && event.training" :to="{ name: 'admin.training', params: { 'id': trainingId } }">Training: {{ getTranslation(event.training).title }}</router-link>
      </div>
    </div>

    <header v-if="!isLoading" class="page-header">
      <PageTitle v-if="event"/>
      <div class="buttons">
        <button v-if="!isLoading && checkPermissions(['academy_training.edit'])" class="btn btn-save" @click="saveTrainingEvent()">
          <span>{{ event && event.id ? 'Trainings data wijzigen' : 'Trainings data toevoegen' }}</span>
        </button>
      </div>
    </header>

    <div class="main-content">
      <div class="pane table-pane">
        <formulate-form v-if="!isLoading" ref="trainingEventForm" v-model="event" name="trainingEventForm">
          <formulate-input name="event_start" type="datetime-local" label="Van"></formulate-input>
          <formulate-input name="event_end" type="datetime-local" label="Tot"></formulate-input>
          <formulate-input name="seats" type="number" label="Seats"></formulate-input>
          <formulate-input :options="activeLocalesFront" name="language" type="select" label="Taal"></formulate-input>
          <formulate-input name="published" type="checkbox" label="Gepubliceerd"></formulate-input>
        </formulate-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import PageTitle from '@/components/admin/PageTitle'
import { DateTime } from 'luxon'

export default {
  name: 'TrainingEvent',
  components: {
    PageTitle
  },
  data: function () {
    return {
      isLoading: true,
      trainingId: this.$route.params.trainingId,
      eventId: this.$route.params.id,
      training: null,
      event: null,
      datetime: DateTime
    }
  },
  async mounted () {
    this.event = { published: false, translations: [{ lang_code: this.activeLocale }] }

    if (this.trainingId && this.eventId) {
      await this.fetchTrainingEvent(this.trainingId, this.eventId)
    } else {
      if (!this.event) {
        this.event = {
          published: false
        }
      }

      await ApiService.fetchTraining(this.trainingId).then(res => {
        this.event.training = res.data
      })
    }

    this.isLoading = false
  },
  methods: {
    fetchTrainingEvent: async function (trainingId, eventId) {
      await ApiService.fetchTrainingEvent(trainingId, eventId).then(res => {
        this.event = res.data
      })
    },
    saveTrainingEvent: async function () {
      this.$formulate.submit('trainingEventForm')
      if (await this.$refs.trainingEventForm.hasValidationErrors()) {
        return true
      }

      let request = null
      if (this.event.id) {
        request = ApiService.patchTrainingEvent(this.trainingId, this.event)
      } else {
        request = ApiService.createTrainingEvent(this.trainingId, this.event)
      }

      await request.then((request) => {
        if (request.status === 200) {
          if (this.event.id) {
            notification('Event data succesvol aangepast!')
          } else {
            notification('Event data succesvol toegevoegd!')
          }

          // this.$router.push({ name: 'admin.training', params: { id: this.trainingId } })
        }
      })
    }
  }
}
</script>
